<template>
  <div>
    <label>
      {{ field.name }}
      <span class="text-error" v-if="field.required">*</span>
    </label>
    <div :class="['input-container', { error: field.validation }]">
      <datetime
        :disabled="statusCampaign != 0 && field.field_profile_type_id != 0"
        v-model="field.value"
        placeholder="DD/MM/YYYY"
        format="dd/MM/yyyy"
        value-zone="Asia/Bangkok"
        :ref="`Data`"
      >
      </datetime>
      <div
        v-if="!(statusCampaign != 0 && field.field_profile_type_id != 0)"
        class="icon-primary text-right"
        @click="$refs.Data.isOpen = true"
      >
        <font-awesome-icon
          icon="calendar-alt"
          class="pointer color-primary"
          color="#B41BB4"
        />
      </div>
    </div>
    <div v-if="field.validation">
      <span class="text-error">กรุณาเลือกวันที่</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldDate",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vdatetime-input {
  border: none;
}
.input-container.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
