<template>
  <div>
    <label>
      {{ name }}
      <span class="text-error" v-if="field.required || isRequired">*</span>
    </label>
    <div :class="['input-container']">
      <datetime
        type="datetime"
        class="border-none"
        @input="$emit('input', $event)"
        :value="value"
        placeholder="DD/MM/YYYY (HH:MM)"
        format="dd/MM/yyyy (HH:mm)"
        value-zone="Asia/Bangkok"
        :ref="`Datatime`"
      >
      </datetime>
      <div
        class="icon-primary text-right"
        @click="$refs.Datatime.isOpen = true"
      >
        <font-awesome-icon
          icon="calendar-alt"
          class="pointer color-primary"
          color="#B41BB4"
        />
      </div>
    </div>
    <!-- <div>
      <span class="text-error">กรุณาเลือกวันที่</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FieldDateTime",
  props: {
    name: {
      required: true,
    },
    field: {
      required: false,
      default: false,
    },
    value: {
      required: true,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
    isRequired: {
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vdatetime-input {
  border: none;
}
.input-container.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
