<template>
  <div>
    <label>{{ field.name }} <span class="text-error">*</span></label>
    <b-form-input
      :class="['input-custom']"
      v-model="field.value"
    ></b-form-input>
    <!-- <div>
      <span class="text-error">กรุณาใส่ข้อมูลให้ถูกต้อง</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FieldTextInput",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
